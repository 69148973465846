export const translation = [
    {
        key: "home",
        en: "Home",
        fr: "Acceuil"
    },
    {
        key: "our partners",
        en: "our partners",
        fr: "Nos partenaires"
    },
    {
        key: "our medical products",
        en: "Medical products",
        fr: "Produits médicaux"
    },
    {
        key: "animal products",
        en: "Animal products",
        fr: "Produits animaux"
    }
    ,
    {
        key: "Our categories",
        en: "Our categories",
        fr: "catégories"
    },
    {
        key: "ABOUT",
        en: "About",
        fr: "à propos"
    }
    ,
    {
        key: "contact",
        en: "contact",
        fr: "contact"
    },
    {
        key: "learn more",
        en: "learn more",
        fr: "En savoir plus"
    },
    {
        key: "partners-sub-title",
        en: "Meet our medical end animal equipment best suppliers in the world",
        fr: "Rencontrez nos meilleurs fournisseurs d'équipements médicaux et animaux au monde"
    },
    {
        key: "about-section-title",
        en: "ABOUT EVOCARE",
        fr: "à propos de EVOCARE"
    },
    {
        key: "about-sub-title",
        en: "Evocare is a new established company in the Tunisian market, Based in Tunis the capital of Tunisia, specializing in the sale and distribution of scientific equipment dedicated to the animal and medical fields. founded by an ambitious and highly qualified team with more than 10 years of experience in the field of biomedical equipment. ",
        fr: "Evocare est une nouvelle société implantée sur le marché tunisien, basée à Tunis la capitale de la Tunisie, spécialisée dans la vente et la distribution de matériel scientifique dédié aux domaines vétérinaire et médical. fondée par une équipe ambitieuse et hautement qualifiée avec plus de 10 ans d'expérience dans le domaine des équipements biomédicaux."
    },
    {
        key: "one-health",
        en: "Our team at EVOCARE are aware of the “One Health” concept, so we believe in the close\n" +
            "                                link between human health, animal health and the global ecological state. This is\n" +
            "                                reflected in our development strategy which began with the promotion of animal health\n" +
            "                                through a wide range of products dedicated to animal clinics and hospitals.",
        fr: `Notre équipe chez EVOCARE est consciente du concept "One Health", nous croyons donc en la proximité lien entre la santé humaine, la santé animale et l'état écologique global. C'est se reflète dans notre stratégie de développement qui a débuté par la promotion de la santé animale à travers une large gamme de produits dédiés aux cliniques et hôpitaux animaux.`
    }
    ,
    {
        key: "product-range-title",
        en: "Our product range",
        fr: "Gamme de produits"
    }
    ,
    {
        key: "laboratory-equipment",
        en: "Laboratory equipment",
        fr: "Équipement de laboratoire"
    }
    ,
    {
        key: "medical-imaging",
        en: "Medical imaging",
        fr: "L'imagerie médicale"
    }
    ,
    {
        key: "operating-room",
        en: "Operating room",
        fr: "Salle d'opération"
    }
    ,
    {
        key: "endoscopy",
        en: "Endoscopy",
        fr: "Endoscopie"
    }
    ,
    {
        key: "intensive-care-equipment",
        en: "Intensive care equipment",
        fr: "Matériel de soins intensifs"
    }
    ,
    {
        key: "our-vision",
        en: "Our vision",
        fr: "Notre vision"
    }
    ,
    {
        key: "our-vision-text",
        en: "We aim to contribute wisdom and strength to the improvement of quality and become a world-class leading enterprise in medical equipment development.",
        fr: "Notre objectif est d'apporter sagesse et force à l'amélioration de la qualité et de devenir une entreprise leader de classe mondiale dans le développement d'équipements médicaux."
    }
    ,
    {
        key: "our-mission",
        en: "Our mission",
        fr: "Notre mission"
    }
    ,
    {
        key: "our-mission-text",
        en: "We strive to successfully provide customers with reliable solutions and services in life science, animal health, and medical devices fields ",
        fr: "Nous nous efforçons de fournir avec succès à nos clients des solutions et des services fiables dans les domaines des sciences de la vie, de la santé animale et des dispositifs médicaux."
    }
    ,
    {
        key: "assuring-service",
        en: "Assuring service",
        fr: "Assurer le service"
    }
    ,
    {
        key: "assuring-service-text",
        en: "Our company is customer-oriented and operates around teamwork, scientific management, and continuous improvement.",
        fr: "Notre entreprise est orientée client et fonctionne autour du travail d'équipe, de la gestion scientifique et de l'amélioration continue."
    }

    ,
    {
        key: "categories-title",
        en: "Our categories",
        fr: "Nos catégories"
    },
    {
        key: "categories-text",
        en: "Medical and animal equipment",
        fr: "Matériel médical et vétérinaire"
    } ,
    {
        key: "pets",
        en: "Pets",
        fr: "animaux domestiques"
    },

    {
        key: "humans",
        en: "Humans",
        fr: "Humains"
    },
    {
        key: "contact-us",
        en: "Contact us",
        fr: "Contactez nous"
    },
    {
        key: "contact-us-text",
        en: "Get information about our medical and animal equipment",
        fr: "Informez-vous sur nos équipements médicaux et animaux"
    },
    {
        key: "get-in-touch",
        en: "Get in touch with us",
        fr: "Prenez contact avec nous"
    },
    {
        key: "name",
        en: "Name and Lastname",
        fr: "Nom et prénom"
    },
    {
        key: "phone",
        en: "Phone",
        fr: "Numéro téléphone"
    },
    {
        key: "subject",
        en: "Subject",
        fr: "Sujet"
    },
    {
        key: "address",
        en: "Address",
        fr: "Adresse"
    },
    {
        key: "post-code",
        en: "Post code",
        fr: "Code postal"
    },
    {
        key: "send",
        en: "Send",
        fr: "Envoyer"
    },
    {
        key: "online",
        en: "Make an online enquiry",
        fr: "Faire une demande en ligne"
    },
    {
        key: "footer-about",
        en: "Evocare is a new established company in the Tunisian market, Based in Tunis the capital of Tunisia, specializing in the sale and distribution of scientific equipment dedicated to the animal and medical fields.",
        fr: "Evocare est une nouvelle société implantée sur le marché tunisien, basée à Tunis la capitale de la Tunisie, spécialisée dans la vente et la distribution de matériel scientifique dédié aux domaines vétérinaire et médical."
    },
    {
        key: "explore",
        en: "Explore",
        fr: "Exploitez"
    },
    {
        key: "our-products",
        en: "Our products",
        fr: "Nos produits"
    },
    {
        key: "working-hours",
        en: "Working hours",
        fr: "Horaires de travail"
    },
    {
        key: "working-hours-text",
        en: "You are welcome to visit us",
        fr: "Vous êtes les bienvenus pour nous rendre visite"
    },
    {
        key: "mon",
        en: "Mon",
        fr: "Lundi"
    },
    {
        key: "fri",
        en: "Fri",
        fr: "Vendredi"
    },
    {
        key: "our-products-description",
        en: "Choose among the best medical and animal equipment in the market",
        fr: "Choisissez parmi les meilleurs équipements médicaux et animaux du marché"
    },
    {
        key: "name-only",
        en: "Name",
        fr: "Prénom"
    },
    {
        key: "last-name",
        en: "Last name",
        fr: "Nom"
    },
    {
        key: "e-mail",
        en: "E-mail",
        fr: "E-mail"
    },
    {
        key: "fill-info",
        en: "Please fill in your information",
        fr: "Veuillez remplir les champs"
    },
    {
        key: "technical-file",
        en: "Technical file",
        fr: "Fiche technique"
    },
    {
        key: "quote",
        en: "Quote",
        fr: "Devis"
    },
    {
        key: "we-are-social",
        en: "We are social",
        fr: "Nous sommes sociaux"
    },
    {
        key: "OUR TRAININGS",
        en: "Our trainings",
        fr: "Nos formations"
    }
    ,
    {
        key: "trainings-text",
        en: "Our trainings",
        fr: "Nos formations"
    },
    {
        key: "trainings-title",
        en: "Our trainings",
        fr: "Nos formations"
    },
    {
        key: "prof",
        en:"Pr. AISSI Adel",
        fr:"Pr. AISSI Adel"
    }
    ,
    {
        key: "prof-s",
        en:"PROFESSEUR SPÉCIALISTE EN CHIRURGIE - IMAGERIE VÉTÉRINAIRE",
        fr:"PROFESSEUR SPÉCIALISTE EN CHIRURGIE - IMAGERIE VÉTÉRINAIRE"
    },
    {
        key: "partner-site",
        en: "partner website",
        fr: "site partenaire"
    },
    {
        key: "references-title",
        en: "Our References",
        fr: "Nos références"
    },
    {
        key: "references-text",
        en: "At EVOCARE, we are privileged to have worked with some of the most influential companies in the industry. Our client portfolio includes a diverse range of esteemed organizations that have placed their trust in us.",
        fr: "Chez EVOCARE, nous avons eu le privilège de travailler avec certaines des entreprises les plus influentes de l'industrie. Notre portefeuille de clients comprend un éventail diversifié d'organisations prestigieuses qui nous ont fait confiance."
    },
    {
        key: "who-we-are",
        en: "Who we are",
        fr: "Qui sommes nous"
    },
    {
        key: "b-med",
        en: "Humain health",
        fr: "Santé humain"
    },
    {
        key: "b-vet",
        en: "Animal health",
        fr: "Santé Vétérinaire"
    },
    {
        key: "b-env",
        en: "Environmental health",
        fr: "Santé Environmental"
    },
    {
        key: "products-and-services",
        en: "Products and services",
        fr: "Produits et services"
    },
    {
        key: "subscription-text",
        en: "In order to pre-subscribe to this training please visit this ",
        fr: "Pour s'inscrire à cette formation, veuillez visiter ce "
    },
    {
        key: "link",
        en: "link",
        fr: "lien"
    }
]