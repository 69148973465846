import {
    AccountTree,
  ClearAll,
  ContactMail,
  GridOn,
  Group,
  NoteAdd,
} from "@material-ui/icons";

const url =
  process.env.NODE_ENV === "production"
    ? "https://www.test.evocare.tn/API/"
    : "http://localhost/evocare/";

export const API_URL = url + "evocare-end-point.php?action=";
export const UPLOAD_URL = url + "upload.php";
export const IMG_URL = url + "images/";
export const modules = [
  {
    label: "products_categories",
    icon: <ClearAll />,
    title: "Gestion des catégories produits",
    uploadables: [{ field: "image", displayInTable: true }],
    staticValues: [
      {
        column: "target",
        values: ["Vet", "Med", "Env"],
      },
    ],
  },
  {
    label: "products_sub_categories",
    icon: <ClearAll />,
    title: "Gestion des sous catégories produits",
    relations: [{ table: "products_categories", on: "category_id" }],
  },
  {
    label: "products",
    icon: <GridOn />,
    title: "Gestion des produits",
    sortables: ["date_created", "date_updated"],
    relations: [{ table: "products_sub_categories", on: "category_id" }],
    uploadables: [
      { field: "image", displayInTable: true },
      { field: "images", multiple: true },
      { field: "file", type: "file" },
      { field: "video", type: "video" },
    ],
  },
  {
    label: "partners",
    icon: <Group />,
    title: "Gestion des partenaires",
    uploadables: [{ field: "image", displayInTable: true }],
  },
  {
    label: "reference",
    icon: <AccountTree />,
    title: "Gestion des références",
    uploadables: [{ field: "image", displayInTable: true }],
  },
  {
    label: "contacts",
    icon: <ContactMail />,
    title: "Contacts clients",
    canAdd: false,
    sortables: ["date"],
  },
  {
    label: "clients_requests",
    icon: <NoteAdd />,
    title: "Les demandes clients",
    canAdd: false,
    sortables: ["date"],
    relations: [{ table: "products", on: "product_id" }],
  },
];

export const colors = {
  primary: "#26c8b6",
};
